.roundBtn {
  position: absolute;
  width: 140px;
  height: 140px;
  top: 90px;
  border-radius: 70px;
  left: 360px;
  margin-left: -70px;
  background-color: #66FF66;
  color: whitesmoke;
  font-size: 22px;
  border: 5px solid white;
}

.roundBtn:hover {
  box-shadow: 0 0 20px rgba(136, 255, 156, 0.932);
  color: white;
}

.tableLeft {
  width: 360px;
  height: 120px;
  position: absolute;
  top: 100px;
}

.tableRight {
  width: 360px;
  height: 120px;
  position: absolute;
  margin-left: 360px;
  top: 100px;
}

.tdNarrow {
  width: 100px;
}

.tdWide {
  width: 300px;
  padding: 0px;
}

.tdWideSmall {
  width: 300px;
  padding: 0px;
  font-size: 17px;
}

#leftAlign {
  text-align: left;
  padding-left: 10px;
  border-left: 1px solid;
}

#rightAlign {
  text-align: right;
  border-right: 1px solid;
  padding-right: 10px;
}

.roundDiv {
  position: absolute;
  width: 130px;
  height: 130px;
  top: 90px;
  border-radius: 80px;
  left: 360px;
  margin-left: -70px;
  background-color: #66FF66;
  color: whitesmoke;
  font-size: 22px;
  border: 5px solid white;
  text-align: center;
  font-size: 20px;
  box-shadow: 0 0 20px rgba(136, 255, 156, 0.932);
  color: white;
}

.innerRound {
  width: 80px;
  margin-left: 25px;
  margin-top: 17px;
}

.gameSetup {
  position: absolute;
  width: 720px;
  left: 550px;
  height: 400px;
  margin-top: 25px;
  background-color: rgba(255, 255, 255, 0.849);
  border: none;
  border-radius: 3px;
  font-size: 22px;
  z-index: 100;
  background-position: center;
}

.gameSetup p {
  background-color: rgba(255, 255, 255, 0.973);
}

.gameSetup table {
  background-color: rgba(255, 255, 255, 0.938);
}

.sideTab {
  position: absolute;
  margin-left: 100px;
  height: 85vh;
  width: 400px;
  margin-top: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  color: black;
  z-index: 1000;
  overflow-y: scroll;
}

.sideTab p {
  margin: 0px;
}

.sideBtn {
  width: 360px;
  height: 40px;
  font-size: 25px;
  text-align: left;
  width: 95%;
  background-color: white;
  margin: 5px;
  padding-left: 10px;
}

.sideBtn button {
  display: flex;
  align-self: baseline;
  justify-content: center;
  margin-top: 5px;
  height: 30px;
  width: 60px;
  font-size: 18px;
  margin-right: 5px;
  float: right;
  background-color: #004256;
  border: none;
  color: whitesmoke;
}

.sideBtn button:hover:enabled {
  box-shadow: 0 7px 15px #004256e5;
  color: white;
}

.sideBtnSmall {
  width: 360px;
  height: 40px;
  font-size: 20px;
  text-align: left;
  width: 95%;
  background-color: white;
  margin: 5px;
  padding-left: 10px;
}

.sideBtnSmall button {
  align-self: baseline;
  margin-top: 5px;
  height: 30px;
  width: 60px;
  font-size: 18px;
  margin-right: 5px;
  background-color: #004256;
  border: none;
  color: whitesmoke;
}

.sideBtnSmall button:hover {
  box-shadow: 0 7px 15px #004256e5;
  color: white;
}

.chatContainer {
  display: flex;
  position: absolute;
  width: 720px;
  left: 550px;
  height: 200px;
  margin-top: 450px;
  background-color: rgba(255, 255, 255, 0.849);
  border: none;
  border-radius: 3px;
  font-size: 22px;
  z-index: 100;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.chatContainer textarea {
  padding-left: 9px;
  padding-right: 9px;
  margin-top: 10px;
  height: 140px;
  width: 700px;
  border: none;
  resize: none;
}

.chatContainer div {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 100%;
}

.chatContainer div input {
  padding-left: 8px;
  padding-right: 8px;
  border: none;
  width: 630px;
  height: 30px;
}

.chatContainer div button {
  background-color: antiquewhite;
  font-size: 14px;
  height: 33px;
  width: 69px;
  border: none;
}

.chatContainer div button:hover {
  background-color: #ceb494;
}

@media screen and (max-width: 1400px) {
  .gameSetup {
    left: 0;
    margin-left: 510px;
  }
  .chatContainer {
    left: 0;
    margin-left: 510px;
  }
}

@media screen and (max-width: 440px) {
  .gameSetup {
    position: absolute;
    margin-left: 0px;
    top: 0px;
  }
  .chatContainer {
    position: absolute;
    margin-left: 0px;
    top: 0px;
  }
  .sideTab {
    position: absolute;
    top: 520px;
    left: -100px;
  }
}

.menuButtons {
  height: 530px;
  width: 600px;
  background-color: rgba(255, 255, 255, 0.904);
  border-radius: 3px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 15px white;
  margin: 0px;
}

.menuButtons p {
  height: 70px;
  width: 96%;
  font-size: 30px;
  border-radius: 3px;
  background-color: #00563f;
  border: none;
  color: white;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-top: 10px;
}

.menuButtons button {
  height: 70px;
  width: 96%;
  font-size: 25px;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #004256;
  border: none;
  color: #eeeeee;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.menuButtons button:hover {
  box-shadow: 0 0 25px #004256e5;
  color: white;
  font-size: 27px;
}

.title2 {
  font-size: 140px;
  color: white;
  font-weight: 900;
  margin: 0px;
  text-shadow: 0 0 15px white;
  font-family: 'Righteous', cursive;
}

.leftMenu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 1200px) {
  .menuButtons {
    width: 400px;
  }
  .menuWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-content: center;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .menuWrapper {
    position: absolute;
  }
  .transparent {
    display: none;
  }
}

.request {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100px;
  left: 50%;
  margin-left: -100px;
  top: 100px;
  border: solid 2px white;
  border-radius: 3px;
  font-size: 14px;
  z-index: 10000;
  background-color: rgba(201, 201, 201, 0.9);
  padding: 0px;
}

.request button {
  display: flex;
  justify-content: space-evenly;
  border: solid 3px #ff8036;
  background-color: #ff8036;
  border-radius: 3px;
  height: 25px;
  width: 70px;
  margin: 2px;
}

.request button:hover {
  box-shadow: 0px 1px 10px #ff8036;
}

.request p {
  margin: 0px;
  padding: 10px;
}

.request div {
  display: flex;
  justify-content: center;
  align-content: center;
}

.loginForm {
  position: absolute;
  padding-top: 20px;
  top: 40%;
  left: 50%;
  width: 400px;
  height: 400px;
  margin: -150px 0 0 -200px;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  background-color: rgba(233, 233, 233, 0.979);
  box-shadow: 0px 0 20px white;
}

.loginForm form {
  margin: 5px;
}

.loginForm input {
  margin: 5px;
  background-color: rgba(240, 240, 240, 0.979);
  border: solid 1px white;
  width: 300px;
  height: 35px;
  font-size: 15px;
  text-align: center;
}

.loginForm input[type=submit] {
  margin-top: 30px;
  margin-bottom: 20px;
  border: none;
  border: solid 3px #00563f;
  background-color: #00563f;
  width: 300px;
  height: 38px;
  font-size: 20px;
  text-align: center;
  color: #ebebeb;
}

.loginForm input[type=submit]:hover {
  box-shadow: 0 0 15px #00563f;
  color: white;
  font-size: 22px;
}

.loginForm .loginFormTitle {
  font-size: 30px;
}

.loginForm .loginFormText {
  font-size: 15px;
  text-align: left;
  margin-left: 50px;
}

.userInfo {
  height: 50px;
  z-index: 3000;
  font-size: 30px;
  width: 100%;
  background-color: #e6e6e6;
  padding-left: 100px;
  position: fixed;
  left: 0;
  top: 0;
  margin-left: -100px;
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.userInfo form {
  align-self: center;
}

.userInfo button {
  align-self: center;
  font-size: 20px;
  width: 120px;
  background-color: #d8a7a7;
  border: none;
  border-radius: 3px;
  float: right;
  margin-right: 10px;
  padding-top: 2px;
  box-shadow: 0 0 10px #d8a7a7;
}

.userInfo button:hover {
  color: #252525;
  font-size: 22px;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
}

.userInfo .usernameDisplay {
  width: 50%;
}

.userInfo .buttonDisplay {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}

.userInfo .resetBtn {
  border: none;
  background: none;
  box-shadow: none;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
}

.title {
  position: absolute;
  top: 30%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.911);
  font-size: 100px;
  border-top: solid 2px lightblue;
  border-bottom: solid 2px lightblue;
  margin-top: 50px;
  letter-spacing: 10px;
  box-shadow: 0 0 15px white;
  justify-self: center;
  align-self: center;
  font-family: 'Righteous', cursive;
}

@-webkit-keyframes menuBtnAnimation {
  from {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes menuBtnAnimation {
  from {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.register {
  position: absolute;
  top: 0;
  height: 70px;
  width: 100%;
  background-color: #e6e6e6;
  letter-spacing: 1px;
}

.register button {
  opacity: 0;
  height: 100%;
  font-size: 25px;
  background-color: transparent;
  width: 120px;
  padding-top: 2px;
  margin-left: 10px;
  margin-right: 20px;
  border: none;
  letter-spacing: 1px;
  -webkit-animation: menuBtnAnimation 0.5s ease forwards;
          animation: menuBtnAnimation 0.5s ease forwards;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.register button:hover {
  color: #5b9b4b;
  text-shadow: 0 0 4Spx #a0bd98;
  font-size: 27px;
  padding-top: 0px;
  cursor: pointer;
}

.mainLink {
  margin-left: 20px;
  margin-right: 20px;
  display: inline-block;
  height: 55px;
  font-size: 25px;
  padding-top: 10px;
  padding-right: 10px;
  text-decoration: none;
  color: black;
  margin-left: 6px;
  font-weight: 600;
  opacity: 0;
  -webkit-animation: menuBtnAnimation 0.5s ease forwards;
          animation: menuBtnAnimation 0.5s ease forwards;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.mainLink:hover {
  color: #5b9b4b;
  text-shadow: 0 0 3px #a0bd98;
}

.mainCanvasWrapper {
  margin: 0px !important;
  display: flex;
  justify-content: baseline;
}

@media screen and (max-width: 440px) {
  .mainLink {
    font-size: 15px;
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 5px;
  }
  .register {
    width: 100vw;
  }
  .register button {
    font-size: 15px;
    width: 70px;
    margin-left: 0px;
    margin-right: 5px;
  }
  .main {
    flex-direction: column;
  }
}

body {
  background-color: black;
  background-image: url("../img/computer-3163437.png");
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-position: center;
  background-attachment: fixed;
  font-family: 'Saira Semi Condensed', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: 'Saira Semi Condensed', sans-serif;
}

button {
  font-family: 'Saira Semi Condensed', sans-serif;
}

canvas {
  width: 400px;
  height: 800px;
  background-color: gray;
  position: relative;
  border: 1px solid black;
}

button {
  font-weight: 600;
}

.canvasBlock {
  margin: 0px !important;
  float: left;
}

@media screen and (max-width: 950px) {
  .main {
    flex-direction: column;
  }
}

.buttonsBlock {
  position: relative;
}

.BackCanvas {
  position: relative;
  background: none;
  background-color: gray;
}

.FrontCanvas {
  position: absolute;
  z-index: 2000;
  background: none;
}

.SideCanvas {
  float: center;
  height: 80px;
  width: 400px;
}

.sideBlock {
  margin-left: -2px !important;
  float: left;
}

.main {
  position: absolute;
  left: 0;
  width: 95%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 0px;
  margin-left: 5px;
  padding-left: 20px;
}

.main div {
  margin: 10px;
}

.mainWrapper {
  width: 100%;
}

.startBtn {
  border: solid 4px seagreen;
  background-color: darkseagreen;
  font-size: 22px;
  width: 110px;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
}

.score {
  width: 200px;
  height: 110px;
  background-color: white;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 20px;
  border: solid 3px orange;
  background-color: yellow;
  border-radius: 3px;
}

.popupOpen {
  position: fixed;
  height: 100px;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.877);
  z-index: 2000;
  margin-left: 200px;
  margin-top: 20px;
  border: solid #ff544b 3px;
  border-radius: 3px;
  z-index: 2000;
}

.popupOpen button {
  margin-right: 5px;
  margin-top: 5px;
  margin-left: 140px;
  background-color: #ff4b93;
  border: solid #ff4b93 2px;
}

.popupClosed {
  display: none;
}

.inviteBtn {
  width: 100%;
  border: solid 2px #9af3ff;
  font-size: 20px;
  background-color: #9af3ff;
}

.inviteBtn:hover {
  border: solid 2px salmon;
}

.container {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: solid 3px #f5d48c;
  font-size: 22px;
  background-color: #f9ff8f;
  border-radius: 3px;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0px;
  width: 0px;
}

.checkmark {
  width: 120px;
  padding-left: 10px;
  padding-right: 10px;
}

.container:hover input ~ .checkmark {
  background-color: #f5d48c;
}

.container input:checked ~ .checkmark {
  border: 2px solid black;
}

.infoTable td {
  margin-left: 15px;
  height: 10px;
}

.rightTD {
  background-color: orange;
  border-radius: 10px;
  width: 150px;
  font-size: 16px;
}

.highscore {
  background-color: rgba(255, 255, 255, 0.918);
  width: 400px;
  text-align: center;
  z-index: 100;
  display: flex;
  flex-direction: column;
  height: 45%;
}

.highscore td {
  width: 100px;
}

.highscoreWrapper {
  left: 20%;
  top: 10%;
  width: 400px;
  margin-left: -200px;
  position: absolute;
}

.winnerPopup {
  margin-left: 5%;
  background-color: rgba(255, 255, 255, 0.87);
  z-index: 10000;
  position: absolute;
  height: 200px;
  width: 200px;
  border: solid 3px salmon;
  text-align: center;
}

.winnerPopup button {
  margin-left: 150px;
}

.error {
  font-size: 15px;
  color: red;
  height: 25px;
}

.transparent {
  opacity: 0.7;
  z-index: 0;
  margin-top: 20px;
  width: 50%;
}

.transparentCanvasBackground {
  position: absolute;
  top: 10%;
  left: 30%;
  margin-left: 100px;
}

.menuWrapper {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.sideTab {
  height: 85vh;
  width: 400px;
  margin-top: 25px;
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  color: black;
  z-index: 1000;
  overflow-y: scroll;
}

.sideBtn {
  width: 360px;
  height: 40px;
  font-size: 25px;
  text-align: left;
}

.sideBtnSmall {
  width: 360px;
  height: 40px;
  font-size: 20px;
  text-align: left;
}

.controls {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #9af3ff;
  height: 100px;
}
/*# sourceMappingURL=style.css.map */